const adminRouter = {
  route: null,
  name: null,
  title: '权限管理',
  type: 'folder',
  icon: 'iconfont icon-huiyuanguanli',
  filePath: 'view/admin/',
  order: null,
  inNav: true,
  permission: ['超级管理员独有权限'],
  children: [
    {
      title: '用户管理',
      type: 'view',
      name: 'userList',
      route: '/admin/user/list',
      filePath: 'view/admin/user/user-list.vue',
      inNav: true,
      icon: 'iconfont icon-huiyuanguanli',
      permission: ['查询所有用户'],
    },
    {
      route: '/admin/group/list',
      name: null,
      title: '分组管理',
      type: 'tab',
      icon: 'iconfont icon-yunyingguanli_fuwufenzuguanli',
      filePath: 'view/admin/group',
      inNav: true,
      children: [
        {
          route: '/admin/group/list',
          type: 'view',
          name: 'groupList',
          inNav: true,
          filePath: 'view/admin/group/group-list.vue',
          title: '分组列表',
          icon: 'iconfont icon-huiyuanguanli',
          permission: ['超级管理员独有权限'],
        },
        {
          route: '/admin/group/add',
          type: 'view',
          name: 'GroupCreate',
          filePath: 'view/admin/group/group-create.vue',
          inNav: true,
          title: '添加分组',
          icon: 'iconfont icon-add',
          permission: ['超级管理员独有权限'],
        },
        {
          route: '/admin/group/edit',
          type: 'view',
          name: 'GroupEdit',
          filePath: 'view/admin/group/group-edit.vue',
          inNav: false,
          title: '修改分组',
          icon: 'iconfont icon-add',
          permission: ['超级管理员独有权限'],
        },
      ],
    },
    {
      route: '/admin/permission/list',
      type: 'view',
      name: 'permission',
      inNav: true,
      icon: 'iconfont icon-jiemiansheji',
      filePath: 'view/admin/permission/permission-list.vue',
      title: '权限列表',
    },
    {
      route: '/admin/setting/list',
      type: 'view',
      name: 'setting',
      inNav: true,
      filePath: 'view/admin/setting/setting-list.vue',
      title: '设置管理',
      icon: 'iconfont icon-shangpinguanli',
      permission: ['得到所有设置'],
    },
    {
      route: '/admin/log/serilog',
      type: 'view',
      name: 'serilog',
      inNav: true,
      filePath: 'view/admin/log/serilog.vue',
      title: 'Serilog日志',
      icon: 'iconfont icon-naozhongxiaoxitixing',
      permission: ['Serilog日志'],
    },
    {
      title: '日志管理',
      type: 'view',
      name: Symbol('log'),
      route: '/log',
      filePath: 'view/log/log.vue',
      inNav: true,
      icon: 'iconfont icon-rizhiguanli',
      order: 2,
      permission: ['查询所有日志'],
    },
    {
      title: '日志面板',
      type: 'view',
      name: Symbol('logdashboard'),
      route: '/log-dashboard',
      filePath: 'view/home/log-dashboard.vue',
      inNav: true,
      icon: 'iconfont icon-rizhiguanli',
      order: 2
    },
  ],
}

export default adminRouter
