/*
 * @Author: Poon hefaye@163.com
 * @Date: 2024-08-21 08:42:25
 * @LastEditors: Poon hefaye@163.com
 * @LastEditTime: 2024-08-22 14:38:45
 * @FilePath: \PansCMS_ADMIN\src\plugin\base\stage-config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const baseRouter = {
  route: null,
  name: null,
  title: "基础资料",
  type: "folder", // 类型: folder, tab, view
  icon: "iconfont icon-tushuguanli",
  order: null,
  inNav: true,
  children: [
    {
      name: null,
      title: "班字排辈管理",
      type: "view",
      name: "ZibeiList",
      route: "/base/zibei/list",
      icon: 'iconfont icon-tuichu',
      filePath: "plugin/base/view/zibei/zibei-list.vue",
      inNav: true,
      permission: ["所有班字排辈"]
    },
    {
      name: null,
      title: "字典管理",
      type: "view",
      name: "ItemList",
      route: "/base/item",
      icon: 'iconfont icon-tuichu',
      filePath: "plugin/base/view/item/item-list.vue",
      inNav: true
    },
    {
      name: null,
      title: "字典类别管理",
      type: "view",
      name: "TypeList",
      icon: 'iconfont icon-top',
      route: "/base/type",
      filePath: "plugin/base/view/type/type-list.vue",
      inNav: true
    },
    {
      name: null,
      title: "本地化语言",
      type: 'tab',
      inNav: true,
      icon: 'iconfont icon-kecheng',
      children: [{
        title: '本地化语言',
        type: 'view',
        name: 'culture-list',
        route: '/base/culture/list',
        filePath: 'plugin/base/view/culture/culture-list.vue',
        inNav: false,
      },
      {
        title: "新增本地化",
        type: "view",
        name: "culture-form",
        route: "/base/culture/form/:id?",
        filePath: "plugin/base/view/culture/culture-form.vue",
        inNav: false,
        props: true
      }]
    }
  ]
};

export default baseRouter;
