const bookRouter = 
{
  route: null,
  name: null,
  title: '字辈管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/base/', // 文件路径
  order: null,
  inNav: false,
  children: [
    {
      name: null,
      title: "班字排辈管理",
      type: "view",
      name: "ZibeiList",
      route: "/base/zibei/list",
      filePath: "plugin/base/view/zibei/zibei-list.vue",
      inNav: true,
      permission: ["所有班字排辈"]
    },
  ],
}

export default bookRouter
